import React, { FC } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { UnsupportedBrowserFragment, UnsupportedBrowserQuery } from '../entities/operationResults';
import theme from '../constants/theme';
import ErrorPage from './organisms/ErrorPage';
import BodyText from './atoms/BodyText';
import { ExternalContentLink } from './atoms/ContentLink';
import Meta from './atoms/Meta';
import { removeTags } from '../utils/removeTags';

export const query = graphql`
    query UnsupportedBrowserQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...UnsupportedBrowserFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment UnsupportedBrowserFragment on Craft_UnsupportedBrowser {
        uri
        title
        illustration {
            url
        }
        color {
            ...ColorFragment
        }
        contentWithTitle {
            ...ContentWithTitle
        }
        introText
        subtitle
        imageGroup {
            ...ImageGroupFragment
        }
        contactDetails {
            email
            phoneNumber
        }
        seo {
            ...MetaFragment
        }
    }

    fragment ContentWithTitle on Craft_ContentWithTitleContenttext {
        bodyTitle
        body
    }

    fragment ColorFragment on Craft_ColorColorCombo {
        color {
            rgb
        }
        accent {
            rgb
        }
    }

    fragment ImageGroupFragment on Craft_ImageGroupImage {
        id
        image {
            filename
            size
            title
            url
        }
        imageLink {
            url
        }
    }
`;

interface Props {
    data: UnsupportedBrowserQuery;
}

const UnsupportedBrowser: FC<Props> = ({ data }) => {
    const entry = data.craft.entry as UnsupportedBrowserFragment;
    const title = entry && entry.title;
    const color = entry && entry.color && entry.color[0];
    const mainColor = color && color.color && color.color.rgb;
    const accent = color && color.accent && color.accent.rgb;
    const meta = entry.seo && entry.seo[0];

    const contentWithTitle = entry && entry.contentWithTitle && entry.contentWithTitle[0];
    const header = contentWithTitle && contentWithTitle.bodyTitle;
    const body = contentWithTitle && contentWithTitle.body;
    const illustration = entry?.illustration?.[0]?.url;
    const textBody = entry && entry.introText && entry.introText;

    const subtitle = entry && entry.subtitle && entry.subtitle;
    const hasGroup = entry && entry.imageGroup && entry.imageGroup;

    const contactGroup = entry && entry.contactDetails && entry.contactDetails[0];
    const email = contactGroup && contactGroup.email;
    const phoneNumber = contactGroup && contactGroup.phoneNumber;

    return mainColor && accent && header && body && illustration ? (
        <ErrorPage
            mainColor={mainColor}
            accent={accent}
            header={header}
            body={body}
            illustration={illustration}
            noIllustrationOnIpad
        >
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : title}
                description={meta && meta.seoDescription ? meta.seoDescription : removeTags(textBody)}
                image={meta && meta.seoImage ? meta.seoImage : [{ url2000: illustration }]}
            />
            {textBody && <SubTitle>{textBody}</SubTitle>}
            <FlexWrap>
                {hasGroup && hasGroup.length > 0 && (
                    <ImageGroup>
                        {subtitle && <BodyText variant="small">{subtitle}</BodyText>}
                        <IconGroup>
                            {hasGroup.map((item) =>
                                item
                                    ? item.id &&
                                      item.imageLink &&
                                      item.imageLink.url && (
                                          <Anchor key={item.id} href={item.imageLink.url} target="_blank">
                                              {/* TODO: Fix this shit */}
                                              {(() => {
                                                  const image = item.image && item.image[0];
                                                  return image ? (
                                                      <Image
                                                          src={image.url || undefined}
                                                          alt={image.title || undefined}
                                                      />
                                                  ) : null;
                                              })()}
                                          </Anchor>
                                      )
                                    : null
                            )}
                        </IconGroup>
                    </ImageGroup>
                )}
                {email && phoneNumber && (
                    <div>
                        <ContactTitle variant="small">Contact</ContactTitle>
                        <BodyText variant="tiny">
                            {phoneNumber}
                            <br />
                            <LinkItem as="a" color={theme.colors.black} href={`mailto:${email}`}>
                                {email}
                            </LinkItem>
                        </BodyText>
                    </div>
                )}
            </FlexWrap>
        </ErrorPage>
    ) : null;
};

const SubTitle = styled(BodyText)`
    margin-bottom: 16rem;
`;

const FlexWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

const ImageGroup = styled.div`
    max-width: 25rem;
    padding-right: 4rem;
`;

const Anchor = styled.a`
    display: inline-block;
    border: 0;
    outline: 0;
`;

const Image = styled.img`
    height: 35px;
    width: 35px;
    outline: 0;
    border: 0;
`;

const LinkItem = styled(ExternalContentLink)`
    display: block;
    color: black;
    border-bottom: 2px solid black;
`;

const IconGroup = styled.div`
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ContactTitle = styled(BodyText)`
    margin-bottom: 4rem;
`;

export default UnsupportedBrowser;
