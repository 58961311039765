import React, { FC } from 'react';
import ErrorGraphicColumn from '../molecules/ErrorGraphicColumn';
import ErrorHeader from '../molecules/ErrorHeader';
import ErrorPageWrapper from '../atoms/ErrorPageWrapper';
import styled from 'styled-components';
import theme from '../../constants/theme';

interface Props {
    mainColor: string;
    accent: string;
    header: string;
    body: string;
    illustration: string;
    noIllustrationOnIpad?: boolean;
}

const ErrorPage: FC<Props> = ({ mainColor, accent, header, body, illustration, noIllustrationOnIpad, children }) => {
    return (
        <ErrorPageWrapper backColor={mainColor}>
            <ErrorTextColumn noIllustrationOnIpad={noIllustrationOnIpad}>
                <ErrorHeader header={header} body={body} />
                {children}
            </ErrorTextColumn>
            <ErrorGraphicColumn
                illustration={illustration}
                header={header || undefined}
                diameter={40}
                accent={accent}
                noIllustrationOnIpad={noIllustrationOnIpad}
            />
        </ErrorPageWrapper>
    );
};

export default ErrorPage;

export const ErrorTextColumn = styled.div<{ noIllustrationOnIpad?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ noIllustrationOnIpad }) => noIllustrationOnIpad && `max-width: 100%;`}
    ${({ noIllustrationOnIpad }) => noIllustrationOnIpad && `width: 100%;`}

    @media screen and (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        ${({ noIllustrationOnIpad }) => noIllustrationOnIpad && `width: inherit;`}
    }
    ${({ noIllustrationOnIpad }) =>
        !noIllustrationOnIpad &&
        `
        @media (${theme.mediaQueries.vertical}) {
            height: 80vh;
        }
    `}
`;
