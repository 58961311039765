import React, { FC } from 'react';
import styled from 'styled-components';
import Title from '../atoms/Title';
import theme from '../../constants/theme';

interface Props {
    header: string;
    body: string;
}

const ErrorHeader: FC<Props> = ({ header, body }) => {
    return (
        <ErrorText>
            <HeaderTitle variant="huge">{header}</HeaderTitle>
            <BodyTitle variant="small">{body}</BodyTitle>
        </ErrorText>
    );
};

export default ErrorHeader;

const ErrorText = styled.div``;

const HeaderTitle = styled(Title)`
    margin-bottom: 50px;

    @media screen and (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        font-size: 10rem;
    }

    @media (${theme.mediaQueries.vertical}) {
        margin-bottom: 40px;
        font-size: 8rem;
        margin-top: 5px;
    }
`;
// TODO: Create new bodyfont
const BodyTitle = styled(Title)`
    line-height: 1.5em;
`;
