import styled from 'styled-components';
import theme from '../../constants/theme';

export default styled.div<{ backColor: string }>`
    background-color: ${({ backColor }) => backColor};
    height: 100vh;
    width: 100vw;
    padding: 20vh 10vw 20vh 20vw;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        padding: 15vh 5vw 15vh 10vw;
    }
    @media screen and (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        padding: 10vh 5vw 10vh 10vw;
    }
    @media screen and (max-width: ${theme.mediaQueries.width.s}), (max-height: ${theme.mediaQueries.height.s}) {
        flex-direction: column;
    }

    @media (${theme.mediaQueries.vertical}) {
        min-height: 100vh;
        height: auto;
        margin-top: -10vh;
        padding: 10vh 5vw 5vh 8vw;
    }

    @media screen and (max-height: ${theme.mediaQueries.height.m}) {
        display: block;
        width: 100%;
    }
`;
