import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import Circle from '../atoms/Circle';

interface Props {
    illustration: string;
    header: string | undefined;
    accent: string;
    diameter: number;
    noIllustrationOnIpad?: boolean;
}

const YellowGraphicColumn: FC<Props> = ({ illustration, header, accent, diameter, noIllustrationOnIpad }) => {
    return (
        <Wrapper>
            <ImageWrapper noIllustrationOnIpad>
                <Image src={illustration} alt={header} />
                <BackgroundCircle diameter={diameter} fill={accent} />
            </ImageWrapper>
        </Wrapper>
    );
};

export default YellowGraphicColumn;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media (${theme.mediaQueries.vertical}) {
        display: none;
    }
`;

const Image = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55vh;
    z-index: 1;

    picture > img {
        width: auto;
        height: auto;
    }
`;

const ImageWrapper = styled.div<{ noIllustrationOnIpad?: boolean }>`
    position: relative;
    height: 50vh;
    right: 0;

    @media screen and (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        ${({ noIllustrationOnIpad }) =>
            !noIllustrationOnIpad
                ? `
            height: 40vh;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        `
                : `
            display: none;
        `}
    }
`;

const BackgroundCircle = styled(Circle)`
    @media (${theme.mediaQueries.vertical}) {
        display: none;
    }
    position: relative;
    z-index: 0;

    @media screen and (min-height: ${theme.mediaQueries.height.m}) and (min-width: ${theme.mediaQueries.width.m}) {
        width: 50vh;
        height: 50vh;
    }
    @media screen and (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        height: 40vh;
        width: 40vh;
    }
`;
